import React from "react";
import { Link } from "gatsby";

import { Title, Text, Box, Span } from "../Core";

interface RestProps {
  [propName: string]: unknown;
}

interface LinkProps {
  link?: string;
}

interface NumPostsProps {
  numPosts?: number;
}

export const CardSidebar: React.FC<RestProps> = ({ children, ...rest }) => (
  <Box bg="ash" borderRadius="10px" mb="30px" p="25px" {...rest}>
    {children}
  </Box>
);

export const Block: React.FC<RestProps> = ({ children, ...rest }) => (
  <Box borderBottom="1px solid #524f73" pt="20px" pb="13px" {...rest}>
    {children}
  </Box>
);

export const TitleSidebar: React.FC<RestProps> = ({ children, ...rest }) => (
  <Title variant="card" fontSize="24px" textColor="light" {...rest}>
    {children}
  </Title>
);

export const TitlePost: React.FC<RestProps & LinkProps> = ({
  link = "/",
  children,
  ...rest
}) => (
  <Title variant="card" fontSize="16px" mb={0} {...rest}>
    <Link to={link}>
      <Span textColor="light">{children}</Span>
    </Link>
  </Title>
);

export const Date: React.FC<RestProps & LinkProps> = ({
  link = "/",
  children,
  ...rest
}) => (
  <Text textColor="lightShade" fontSize="14px" {...rest}>
    {children}
  </Text>
);

export const CatList: React.FC<RestProps> = ({ children, ...rest }) => (
  <ul
    // @ts-expect-error ul does not expect css attribute, but for some reason it works
    css={`
      list-style: none;
      margin: 0;
      padding: 0;
    `}
    {...rest}
  >
    {children}
  </ul>
);

export const CatListItem: React.FC<RestProps & LinkProps & NumPostsProps> = ({
  link = "/",
  numPosts = 20,
  children,
  ...rest
}) => (
  <li
    {...rest}
    // @ts-expect-error li does not expect css attribute, but for some reason it works
    css={`
      margin-bottom: 13px;
    `}
  >
    <Link to={link}>
      <Span textColor="light">{children}</Span>{" "}
      <Span textColor="lightShade">- {numPosts} Posts</Span>
    </Link>
  </li>
);
